import _ from 'lodash';
import { Y, ydoc } from '../';
import shallowEqual from './utils/shallowEqual';
import assignDeep from 'assign-deep';
import { sharedReducerMap } from './index';
import { globalUndoManager } from "../../undo-redo";
import { CRDTState, PublishState, YTransactionTypes } from '../../../globals';
import { store } from '../../../index';
import * as Sentry from "@sentry/browser";

const typesNotAllowedToMutate = [];

const createYDocSegment = (path) => {

	let cursor;
	let segment;

	ydoc.transact(() => {

		cursor = ydoc.getMap('draft-store');

		const segments = path.split('.');

		// Walk through the path to the reducer and ensure it's segments exist.
		for (let i = 0; i < segments.length; ++i) {
			segment = segments[i];

			if(!cursor.has(segment)) {
				cursor.set(segment, new Y.Map());
				typesNotAllowedToMutate.push(cursor.get(segment))
			}

			cursor = cursor.get(segment);
		}

	}, YTransactionTypes.NotUndoable);


	return cursor;

};

ydoc.on('afterTransaction', transaction => {

	Y.iterateDeletedStructs(transaction, transaction.deleteSet, item => {

		const typeIllegallyDeleted = typesNotAllowedToMutate.find(type => type._item === item);

		if(
			typeIllegallyDeleted
			// this is ok when discarding the doc
			&& store.getState().adminState.crdt.publishState !== PublishState.Discarding
		) {
			console.log('Detected a delete on a type that is referenced in memory. Reload the window to avoid data loss.');
			window.location.reload();
		}
	});

});

const setDocumentDraftStates = (eventsWithDraftableChanges) => {

	if(eventsWithDraftableChanges.length > 0) {

			ydoc.transact(() => {
			
				const { CRDTItem: sharedAdminState } = getCRDTItem({
					reducer: 'adminState',
					item: 'crdt',
					readOnly: true
				});

				if(sharedAdminState && sharedAdminState.get('publishState') !== PublishState.Draft) {
					console.log('setting publishState to Draft')
					sharedAdminState.set('publishState', PublishState.Draft);
				}

				eventsWithDraftableChanges.forEach(event => {

					let changedType = event.target;

					while(changedType) {

						if(
							changedType instanceof Y.Map 
							// If the type has a crdt_state field
							&& changedType.has('crdt_state') 
							// and was previously published 
							&& changedType.get('crdt_state') === CRDTState.Published
						) {
							// set it to draft
							changedType.set('crdt_state', CRDTState.Draft);
						}

						changedType = changedType.parent;

					}

				});

			}, YTransactionTypes.NotUndoable);

		}

}

export const sharedReducer = (reducer, options) => {

	const { path, ignoredPaths = [] } = options;

	if(typeof path !== "string") {
		throw 'No valid reducer path given. Please add \'path\' to the sharedReducer config.';
	}
	
	// Replicate the reducer path in YJS. Do this up to the root of this shared reducer.
	// We want this root so we can populate the initial state of the entire reducer.
	const sharedReducerType = createYDocSegment(path);

	// Store the shared reducer by it's path
	sharedReducerMap.set(path, {
		type: sharedReducerType,
		ignoredPaths
	});

	// filter out any ignored paths that found their way into the CRDT already
	ignoredPaths.forEach(ignoredPath => {

		const ignoredPathParts = ignoredPath.split('.');
		let cursor = sharedReducerType;

		for(let i = 0; i < ignoredPathParts.length; i++) {

			if(cursor instanceof Y.Map && cursor.has(ignoredPathParts[i])) {
				
				if(i >= ignoredPathParts.length - 1) {
					// last part of the path. If this key exists, it's ignored 
					// and should be deleted
					cursor.delete(ignoredPathParts[i]);
					console.log('deleted', ignoredPath)
				} else {
					// update the cursor to the new part of the path
					cursor = cursor.get(ignoredPathParts[i]);
				}

			} else {
				// path not present in the CRDT, abandon search
				break;
			}

		}

	});

	// add undo/redo functionality to the type
	globalUndoManager.YJSUndoController(sharedReducerType);

	let initialState;

	return (state = initialState, action = {}) => {

		// this is the first time we run this.
		if(!initialState) {

			if(state === undefined) {

				// ignore redux probe actions
				return reducer(state, action)

			} else {

				// merge in the CRDT state on top of the live state. This ensures the reducer is synced
				// with the latest draft data.
				state = initialState = assignDeep(_.cloneDeep(state), sharedReducerType.toJSON());

				// console.log('Initializing reducer', path, ' by combining live state', state, 'with CRDT data', sharedReducerType.toJSON())
				// console.log('final result', state);
				
			}

		}

		if( action.type === '@@y-redux/CRDT_CHANGED' ) {

			const matchedTransactions = action.payload.filter(transaction => transaction.sharedReducerType === sharedReducerType);

			if(matchedTransactions.length > 0) {

				const newState = {...state};
				const eventsWithDraftableChanges = [];

				matchedTransactions.forEach(({event, relativePath}) => {

					const eventCanCauseDraftableChange = 
						event.path[0] !== 'adminState' 
						&& event.transaction.local === true
						&& event.transaction.origin !== YTransactionTypes.NotPublishable
						&& event.transaction.origin !== YTransactionTypes.NotUndoableNotPublishable;

					let draftableChangeOccurred = false;

					const eventPath = relativePath.length > 0 ? relativePath.split('.') : [];

					// Clone every part of the state up until the targeted field. 
					//
					// Cloning the full path is important so Redux can pick up nested changes without implicitly subscribing. 
					// For example:
					// if something nested 3 levels inside of a page changes, only the page reducer itself 
					// and the nested object would change. If you subscribe to the page object itself 
					// (sandwiched in between, but not directly affected) you would not be notified of the 
					// nested change unless specifically subscribed to the nested object

					for (let i = 0; i < eventPath.length -1; ++i) {
						
						const path = eventPath.slice(0, i + 1);

						// left is old state, right is new state
						const l = _.get(state, path);
						const r = _.get(newState, path);

						if(l && r && l === r) {
							// left and right are equal. Clone left into right (the new state)
							_.set(newState, path, _.clone(l));
						}

					}

					let depth = 0;
					let parentType = event.target;

					while(parentType) {

						// Special case for events eminating from inside of a XMLFragment. Redux only
						// represents these as strings, so we need to traverse up to the Fragment, serialize
						// it, and insert the fragment into redux as string.
						if(parentType.constructor === Y.XmlFragment) {
							
							// Slice off the internal bits of the event path. We only want
							// the path to the fragment
							const parentPath = eventPath.slice(0, eventPath.length - depth);

							// Insert the entire fragment as string into redux.
							_.set(newState, parentPath, parentType.toString());

							if(eventCanCauseDraftableChange) {
								eventsWithDraftableChanges.push(event);
							}

							// event has been handled, don't go further.
							return;
						}

						// move up
						depth++;
						parentType = parentType.parent;
					}

					// handle an array change
					if(event instanceof Y.YArrayEvent) {

						/*
						 * Aart killed this because it's proving to not always be reliable
						 * to update arrays incrementally. Things can get out of sync this way.
						 * For now just serialize the entire array and replace the whole thing on
						 * every change.
						 */

//						// get the field that contains our changes and duplicate it for
//						// an immutable state update.
//						const targetField = eventPath.length > 0 ? _.clone(_.get(newState, eventPath)) : newState;

// 						let cursor = 0;
// 
// 						event.changes.delta.forEach(intent => {
// 
// 							if(intent.retain) {
// 
// 								// we're keeping these indexes. Move the cursor forward x items
// 								cursor += intent.retain;
// 
// 							} else if(intent.insert) {
// 
// 								// If the new values can be serialzed, do it.
// 								let newArrayItems = intent.insert.map(item => item?.toJSON ? item.toJSON() : item);
// 
// 								// insert x amount of items at current cursor index
// 								targetField.splice(cursor, 0, ...newArrayItems);
// 								
// 								// forward the cursor by the amount of items inserted
// 								cursor += intent.insert.length;
// 
// 							} else if(intent.delete) {
// 
// 								// delete x amount of items at current cursor index
// 								targetField.splice(cursor, intent.delete);
// 								
// 								// move the cursor forward to the next item
// 								cursor++;
// 
// 							}
// 
// 						});
//						
//						// Replace the old field with the new updated field
//						_.set(newState, eventPath, targetField);

						// Replace the old field with the new updated field
						_.set(newState, eventPath, event.target.toJSON());

						// any Array update counts as draftable for now.
						if(eventCanCauseDraftableChange) {
							draftableChangeOccurred = true;
						}

					} else if(event instanceof Y.YMapEvent) {

						// get the field that contains our changes and duplicate it for
						// an immutable state update.
						const targetField = eventPath.length > 0 ? _.clone(_.get(newState, eventPath)): newState;

						event.changes.keys.forEach((value, key) => {

							if(value.action === "delete") {

								delete targetField[key];

								// map delete counts as draftable
								if(eventCanCauseDraftableChange) {
									draftableChangeOccurred = true;
								}

							} else {

								let newFieldValue = event.target.get(key);

								// Redux only wants serialized state, no YTypes. If the 
								// new value can be serialzed, do it.
								if(newFieldValue?.toJSON){
									newFieldValue = newFieldValue.toJSON();
								}

								// when adding a new key, merge the CRDT data over existing state
								if(value.action === 'add') {

									// use `state` here as it's not affected by any other
									// events handled before in the current loop
									const existingState = _.get(state, [...eventPath, key]);

									if(
										existingState
										&& _.isObjectLike(existingState)
										&& _.isObjectLike(newFieldValue)
									) {
										// overlay CRDT data on top of Redux state. Anything in the CRDT
										// will override existing state (live data)
										newFieldValue = assignDeep(existingState, newFieldValue)
									}

								}

								if(targetField[key] !== newFieldValue) {
									// This change is only draftable if the new item is different
									if(eventCanCauseDraftableChange) {
										draftableChangeOccurred = true;
									}
								}

								targetField[key] = newFieldValue;

							}

						})

						// Replace the old field with the new updated field
						_.set(newState, eventPath, targetField);

					} else if(event instanceof Y.YTextEvent) {

						const textFieldPath = eventPath[eventPath.length - 1];
						const parentPath = eventPath.slice(0, eventPath.length -1);

						// get the field that contains our changes and duplicate it for
						// an immutable state update.
						const parentObject = parentPath.length > 0 ? _.clone(_.get(newState, parentPath)) : newState;

						// set the changed text field to it's new value
						parentObject[textFieldPath] = event.target.toString();

						// Replace the old field with the new updated field
						_.set(newState, parentPath, parentObject);

						// YJS won't emit a change for a text that wasn't actually changed. 
						// Always count these as draftable
						if(eventCanCauseDraftableChange) {
							draftableChangeOccurred = true;
						}

					} else {

						// we don't know how to handle this event. Log an error and make sure we 
						// add in support for this event.
						console.error('Unimplemented event type. Unable to sync CRDT to state...');

					}

					if(draftableChangeOccurred) {
						eventsWithDraftableChanges.push(event);
					}

				});

				Promise.resolve().then(() => {
					// debounce this to prevent redux from running store.getState() while this reducer is executing.
					setDocumentDraftStates(eventsWithDraftableChanges);
				});

				return newState;
			}
		}

		// run the original reducer
		let newState = reducer(state, action);
		
		if(shallowEqual(state, newState) === false) {
			
			// overlay CRDT data on top of Redux state. Anything in the CRDT
			// will override existing state (live data)
			newState = assignDeep(newState, sharedReducerType.toJSON());

		}
		
		return newState;

	}

}